<template lang='pug'>
head-meta(title-page="Мастер-классы — Музей «Шоколадный Выборг»",
  description="Узнайте историю шоколада и создайте свой собственный рецепт. Расписание, цены и контакты."
)
the-master
contacts-line
</template>

<script>
import TheMaster from '../components/master/TheMaster'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'MasterPage',
  components: { ContactsLine, HeadMeta, TheMaster }
}
</script>
