<template lang='pug'>
article.master
  .master__cont.cont
    h1.master__caption.caption Мастер-классы
    p.master__lid
      | В нашем музее царит атмосфера сладкого волшебства, которая привлекает детей и взрослых. Каждый может оказаться
      | в этой сказке, ведь наши мастера приглашают всех желающих освоить шоколадное искусство.
    .master__area
      .master__box
        h2.master__headline Вы узнаете
        p.master__text
          | Историю шоколада, познакомитесь с секретами его приготовления и лично освоите кулинарные техники, которыми
          | владеют шоколатье
      .master__box
        h2.master__headline Вы получите
        p.master__text
          | Шоколадную фигурку, приготовленную и украшенную вами, а также диплом «Юный шоколатье», который станет
          | приятным напоминанием об этом чудесном опыте
    h2.master__title.master__title_price.title Стоимость и длительность мастер-класса
    .master__wrapper
      .master__group.master__group_team
        h3.master__headline.master__headline_price.headline
          | В группе:
        span.master__price-text группа от 2 до 5 человек — 1500&nbsp;₽/чел.
        span.master__price-text группа от 6 до 15 человек — 1300&nbsp;₽/чел.
        span.master__duration Продолжительность 1 час
      .master__group
        h3.master__headline.master__headline_price.headline
          | Индивидуально:
        span.master__price-text
          | 1 человек — 2000&nbsp;₽/чел.
        span.master__duration
          | Продолжительность 1 час, также вы можете
          |
          router-link.master__link(:to="{ name: 'Birthdays' }") заказать день рождения в формате мастер-класса
    p.master__link-text.text-big
      | Актуальное расписание вы можете увидеть в
      |
      a.master__link(:href="vkSocial.link", :title="vkSocial.name") нашей группе ВК
    event-contact
    event-gallery(
      :images="images",
      headline="Мастер-класс для детей",
      text="Ребятам рассказали про шоколад, как правильно темперировать его и заливать в формы. Они сделали себе фигурки, за что получили диплом юного шоколатье")
</template>

<script>
import '@/assets/styles/components/master.sass'
import EventGallery from '../EventGallery'
import EventContact from '../EventContact'
import { socials } from '../../assets/scripts/socials'

export default {
  name: 'TheMaster',
  components: { EventContact, EventGallery },
  data () {
    return {
      socials,
      images: [
        { path: 'images/photo-gallery/photo-gallery-5', alt: 'Юные шоколатье в Музее «Шоколадный Выборг» фото', mod: 'small' },
        { path: 'images/photo-gallery/photo-gallery-6', alt: 'Мероприятия в Музее «Шоколадный Выборг» фото', mod: 'small' }
      ]
    }
  },
  computed: {
    vkSocial () {
      return socials.find(social => social.mod === 'vk')
    }
  }
}
</script>
